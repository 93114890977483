import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'semantic-ui-css/semantic.min.css';
import UserAuthorizationCheck from './UserAuthorizationCheck';
import AuthProvider from './features/auth/components/AuthProvider';

import configureStore, { history } from './store';

const store = configureStore(); /* provide initial state if any */
// serviceWorker.unregister();
ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      {' '}
      {/* place ConnectedRouter under Provider */}
      <AuthProvider navigate={history.push}>
        <UserAuthorizationCheck />{' '}
      </AuthProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
