import React from 'react';

import { Loader, Dimmer } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

const Loading = () => {
  return (
    <div>
      <Dimmer active inverted>
        <Loader>Loading</Loader>
      </Dimmer>
    </div>
  );
};

export default Loading;
