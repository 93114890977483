import { useContext } from 'react';
import { AuthContext } from '../components/AuthProvider';
import logoutAction from './actions/logout';
import error from './actions/error';
import handleAuthResponse from './funcions/handleAuthResponse';
import isAuthResponseValid from '../functions/isAuthResponseValid';

const getSessionFromAuthResponse = (authResponse) => {
  if (
    authResponse &&
    authResponse.session_resource &&
    authResponse.session_resource.session
  ) {
    return authResponse.session_resource.session;
  }

  return null;
};

const useAuth = () => {
  const { state, dispatch, navigate } = useContext(AuthContext);

  const login = () => {
    navigate('/login'); // TODO: this is a placeholder
  };

  const logout = () => {
    dispatch(logoutAction());
    navigate('/'); // TODO: this is a placeholder
  };

  const handleAuthentication = (authResponse) => {
    handleAuthResponse({ dispatch, authResponse });
    navigate('/'); // TODO: this is a placeholder
  };

  const handleAuthenticationError = (errorType, errorIn) => {
    dispatch(error(errorType, errorIn));
  };

  const getIsLoggedIn = () => {
    const { authResponse } = state;
    if (authResponse) {
      return isAuthResponseValid(authResponse);
    }
    return false;
  };

  const getUser = () => {
    const { authResponse } = state;
    const session = getSessionFromAuthResponse(authResponse);
    if (session) {
      const { login_name: loginName } = session;
      return loginName;
    }
    return null;
  };

  const getUserFullName = () => {
    const { authResponse } = state;
    const session = getSessionFromAuthResponse(authResponse);
    if (session) {
      const { user_full_name: userFullName } = session;
      return userFullName;
    }
    return null;
  };

  return {
    login,
    logout,
    handleAuthentication,
    handleAuthenticationError,
    getIsLoggedIn,
    getUser,
    getUserFullName,
  };
};

export default useAuth;
