import React from 'react';
import useForm from 'react-hook-form';
import classNames from 'classnames';
import useAuth from '../hooks/useAuth';
import otmmAuth from '../apis/otmmAuth';
import './MockLogin.css';

const MockLogin = () => {
  const { handleAuthentication, handleAuthenticationError } = useAuth();
  const { register, handleSubmit, setError, errors } = useForm(); // initialise the hook
  const onSubmit = (data) => {
    otmmAuth
      .post('', `username=${data.username}&password=${data.password}`)
      .then((response) => {
        const now = new Date();
        response.data.expiresAt = now.getTime() + 30 * 60 * 1000;
        handleAuthentication(response.data);
      })
      .catch((error) => {
        let message = 'You entered an incorrect username or password.';
        if (
          typeof error.response.data === 'object' &&
          'exception_body' in error.response.data
        ) {
          message = error.response.data.exception_body.message;
        } else if (error.response.status === 403) {
          message =
            'User account is locked. Please contact system administrator';
        }
        handleAuthenticationError('api', error);
        setError('credentials', 'api', message);
      });
  };

  const formClass = classNames({
    ui: true,
    large: true,
    form: true,
    dark: true,
    error: errors.credentials,
  });
  const usernameClass = classNames({
    field: true,
    error: errors.username,
  });
  const passwordClass = classNames({
    field: true,
    error: errors.password,
  });

  return (
    <div className="ui middle aligned center aligned grid">
      <div className="column login-form">
        <h2 className="ui grey header">
          <div className="content">Login to UK MOD Readiness</div>
        </h2>
        <form className={formClass} onSubmit={handleSubmit(onSubmit)}>
          <div className="ui segment">
            <div className={usernameClass}>
              <div className="ui left icon input">
                <i className="user icon" />
                <input
                  type="text"
                  name="username"
                  placeholder="Username"
                  ref={register({ required: true })}
                />
                {errors.username && 'Username is required.'}
              </div>
            </div>
            <div className={passwordClass}>
              <div className="ui left icon input">
                <i className="lock icon" />
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  ref={register({ required: true })}
                />
                {errors.password && 'Password is required.'}
              </div>
            </div>
            <div className="field">
              <button
                type="submit"
                className="ui fluid large black submit button"
              >
                Login
              </button>
            </div>
            <div className="ui error message">
              <div className="content">
                <p>{errors.credentials && errors.credentials.message}</p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MockLogin;
