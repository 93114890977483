import React, { Suspense } from 'react';
import useAuth from './features/auth/hooks/useAuth';
import MockLogin from './features/auth/components/MockLogin';
import Loading from './Loading';
import 'semantic-ui-css/semantic.min.css';

import './index.css';

const App = React.lazy(() => import('./App'));

const UserAuthorizationCheck = () => {
  const { getIsLoggedIn } = useAuth();
  const isLoggedIn = getIsLoggedIn();
  return (
    <>
      {isLoggedIn ? (
        <Suspense fallback={<Loading />}>
          <App />
        </Suspense>
      ) : (
        <MockLogin />
      )}
    </>
  );
};

export default UserAuthorizationCheck;
