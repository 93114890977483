import React, { createContext, useReducer, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import authReducer from '../hooks/reducers/authReducer';
import handleAuthResponse from '../hooks/funcions/handleAuthResponse';
import checkSession from '../functions/checkSession';
import startAuthentication from '../hooks/actions/startAuthentication';
import defaultAuthState from '../constants/defaultAuthState';

const AuthContext = createContext();

const AuthProvider = ({ children, navigate }) => {
  const callbackDomain =
    typeof window !== 'undefined'
      ? `${window.location.protocol}//${window.location.host}`
      : 'http://localhost:8000';

  const [state, dispatch] = useReducer(authReducer, defaultAuthState);

  const [contextValue, setContextValue] = useState({
    state,
    dispatch,
    callback_domain: callbackDomain,
    navigate,
  });

  // Update context value and trigger re-render
  // This patterns avoids unnecessary deep renders
  // https://reactjs.org/docs/context.html#caveats
  useEffect(() => {
    setContextValue({ ...contextValue, state });
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  // Verify user is logged-in on AuthProvider mount
  useEffect(() => {
    dispatch(startAuthentication());
    const authResponse = checkSession({ dispatch });
    handleAuthResponse({ dispatch, authResponse });
  }, []);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.any,
  navigate: PropTypes.func,
};

AuthProvider.defaultProps = {
  children: null,
  navigate: () => {},
};

export default AuthProvider;
export { AuthContext };
