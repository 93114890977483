import { LOGIN } from '../constants/actionTypes';

const login = (authResponse) => {
  return {
    type: LOGIN,
    authResponse,
  };
};

export default login;
