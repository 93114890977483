import { ERROR } from '../constants/actionTypes';

const error = (errorType, errorIn) => {
  return {
    type: ERROR,
    error: errorIn,
    errorType,
  };
};

export default error;
